.bundle-image {
  height: 76vh !important;
  width: 100%;
}

.ant-image {
  width: 100%;
}

@media (max-width: 768px) {
  .bundle-image {
    height: auto !important;
  }
}

.canvas-image {
  width: 800px;
  height: 720px;
}

@media (max-width: 768px) {
  .canvas-image {
    width: 600px;
    height: 540px;
  }
}

@media (max-width: 600px) {
  .canvas-image {
    width: 450px;
    height: 360px;
  }
}

@media (max-width: 450px) {
  .canvas-image {
    width: 320px;
    height: 240px;
  }
}

.qr-scanner {
  background: white;
  border: black 4px solid;
  border-radius: 8px;
  width: 720px;
  height: 720px;
}

.qr-instruction-input::placeholder {
  color: orange;
}

@media (max-width: 768px) {
  .qr-scanner {
    width: 600px;
    height: 600px;
  }
}

@media (max-width: 618px) {
  .qr-scanner {
    width: 450px;
    height: 450px;
  }
}

@media (max-width: 468px) {
  .qr-scanner {
    width: 320px;
    height: 320px;
  }
}

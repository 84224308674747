.bundle-stage-title {
  font-weight: lighter;
  font-size: x-large;
}

.bundle-stage-text {
  text-align: center;
}

.bundle-stage-type {
  font-weight: bold;
  font-size: large;
}

@media (max-width: 576px) {
  .stage-divider {
    display: none;
  }
}

/* Main container for centering the component */

.camera-group {
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
  cursor: default;
  align-items: center;
}

.flash-button {
  position: absolute;
  z-index: 2;
  right: 2.5%;
  top: 1.25%;
}

.camera-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  border-radius: 20px;
  box-sizing: border-box;
}

.camera-container {
  height: 100%;
  width: 100%;
  aspect-ratio: 9 / 16;
  display: flex;
  justify-content: center;
}

.cam-frame {
  width: 100%;
  /* max-height: 100%; */
  aspect-ratio: 9 / 16;
}

.cam-subframe {
  display: inherit;
  position: relative;
  width: 100%;
  border-radius: 20px;
}

.cam-top {
  display: flex;
  position: absolute;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  z-index: 20;
  padding-top: 12px;
  padding-left: 16px;
  padding-right: 16px;
}

.camera-section {
  position: relative;
  display: flex;
  max-width: 1300px;
  max-height: 866px;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.camera-wrapper {
  height: 100%;
  padding: 4%;
  display: flex;
  box-sizing: border-box;
  z-index: 2;
  position: relative;
}

.camera-wrapper.wrap {
  width: 50%;
  justify-content: end;
  inset-inline-start: 10%;
}

.cam-window {
  background: rgba(0, 0, 0, 0.2);
  mix-blend-mode: normal;
  border: 2px solid rgba(151, 151, 151, 0.6);
  backdrop-filter: none;
}

.icon-window {
  background: var(--elevationBackground);
  border-radius: 15px;
  box-shadow: var(--sigElevationCellShadow);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  margin: auto;
  border-radius: 20px;
}

/* Container for the video element */
.video-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video {
  object-fit: contain;
  overflow-clip-margin: content-box;
  overflow: clip;
}

/* Styling for the video element */
.video-frame {
  width: 100%;
  height: 100%;
  aspect-ratio: 9 / 16;
  object-fit: cover;
  border-radius: 20px;
}

.video-rectangle {
  position: absolute;
  width: 90%;
  height: 72.5%;
  border: 5px solid white;
  opacity: 0.7;
}

.video-circle {
  position: absolute;
  width: 90%;
  height: 0;
  padding-bottom: 90%;
  border: 5px solid white;
  border-radius: 50%;
  opacity: 0.7;
  z-index: 10;
}

/* Capture button styling */
.capture-button {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 80px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 10;
  padding: 0;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

/* Styling for the camera icon inside the capture button */
.image-button {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  height: 200px;
  background-color: rgb(129, 129, 129);
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 10;
  padding: 0;
}

.close-button {
  position: absolute;
  bottom: 20px;
  left: 20%;
  transform: translateX(-50%);
  width: 60px;
  height: 60px;
  background-color: #f53c3c;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 10;
  padding: 0;
}

/* Styling for the camera icon inside the close button */
.close-button img {
  width: 50px;
  height: 50px;
}

.confirm-button {
  position: absolute;
  bottom: 20px;
  left: 80%;
  transform: translateX(-50%);
  width: 60px;
  height: 60px;
  background-color: rgb(42, 255, 34);
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 10;
  padding: 0;
}

/* Styling for the camera icon inside the confirm button */
.confirm-button img {
  width: 50px;
  height: 50px;
}

/* Settings button for selecting camera devices */
.settings-button {
  position: absolute;
  top: 10px;
  right: 10px;
}

/* Dropdown for selecting camera devices */
.camera-select {
  display: block;
  background: rgba(255, 255, 255, 0.8);
  border: none;
  padding: 5px;
  border-radius: 5px;
  font-size: 14px;
}

/* Container for the captured image preview */
.preview-container {
  position: relative;
  width: 100%;
  height: 100%;
  /* max-width: 450px; */
  aspect-ratio: 9 / 16;
  display: flex;
  background-color: #000;
  border-radius: 10px;
  overflow: hidden;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

/* Styling for the preview image */
.preview-image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.preview-buttons {
  user-select: none;
  display: flex;
  background: var(--sigColorAlwaysBlack);
  padding: 12px;
  box-sizing: border-box;
  gap: 8px;
  max-height: 60px;
  flex-direction: row;
  flex-wrap: nowrap;
}

.loading-container {
  display: flex;
  position: absolute;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.display-row {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.responsive-row {
  display: flex;
  flex-wrap: nowrap;
}

.responsive-col {
  flex: 1;
  min-width: 0;
}

/* Media queries for tablet and mobile responsiveness */
@media (max-width: 1024px) {
  .camera-container {
    flex: 1;
    max-width: 100%;
  }

  .camera-component {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 75vh;
    border-radius: 20px;
    box-sizing: border-box;
  }

  .capture-button {
    width: 100px;
    height: 100px;
  }

  .capture-button img {
    width: 25px;
    height: 25px;
  }

  button {
    margin: 5px;
    padding: 8px;
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .camera-container {
    max-width: 100%;
  }

  .camera-component {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 75vh;
    border-radius: 20px;
    box-sizing: border-box;
  }

  .capture-button {
    width: 45px;
    height: 45px;
  }

  .capture-button img {
    width: 22px;
    height: 22px;
  }

  button {
    margin: 5px;
    padding: 8px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .camera-container {
    max-width: 100%;
  }

  .camera-component {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 75vh;
    border-radius: 20px;
    box-sizing: border-box;
  }

  .capture-button {
    width: 50px;
    height: 50px;
  }

  .capture-button img {
    width: 20px;
    height: 20px;
  }

  button {
    margin: 5px;
    padding: 6px;
    font-size: 12px;
  }

  .responsive-col {
    width: 100%;
    margin-bottom: 8px;
  }

  .display-row {
    justify-content: center;
  }

  .icon-window {
    background: var(--elevationBackground);
    border-radius: 15px;
    box-shadow: var(--sigElevationCellShadow);
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;
    margin: auto;
    border-radius: 20px;
  }
}

.camera-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  height: 80vh;
}

.image-boundary {
  width: 100%;
  max-width: 450px;
  aspect-ratio: 9 / 16;
  border: 2px dashed #ccc;
  border-radius: 10px;
  margin-top: 20px;
}
